import two from "../assets/images/img_two.jpg";
import { useMatch, useNavigate, useParams } from "react-router-dom";
import { GlobalContext } from "../context/Provider";
import henceforthApi from "../utils/henceforthApi";
import { useState, useEffect, useContext } from "react";
import PaginationLayout from "../components/PaginationLayout";
interface photo {
  image_url: string
}
const PrivatePhoto = () => {
  const navigate = useNavigate();
  const match: any = useMatch("/creator/:id/photos/private/:page");
  const { authState } = useContext(GlobalContext);
  const [state, setState] = useState({
    data: [] as Array<photo>,
    total_count: 0,
  });
  henceforthApi.setToken(authState.access_token);
  const onChangePagination = (newVal: any) => {
    navigate(`/creator/${match.params.id}/photos/public/${newVal}`);
  };
  const getPrivatePhotos = async () => {
    // const apiRes = await henceforthApi.Creator.getPhotos(
    //   match.params.id,
    //   "PRIVATE",
    //   Number(match.params.page) - 1
    // );

    // setState(apiRes.data);
  };

  useEffect(() => {
    getPrivatePhotos();
  }, [match.params.page]);

  return (
    <div className="ibox animated fadeInRight">
      <div className="ibox-title d-flex align-items-center justify-content-between">
        <h5>Private Photos</h5>
      </div>
      <div className="ibox-content">
        <div className="row mt-0 mb-4">
          {state.data.map((photo) => {
            return (
              <div className="col-md-2 mt-2 mb-2">
                <div className="public-both">
                  <div className="public-pic">
                    <img
                      src={
                        photo.image_url
                          ? `${henceforthApi.API_FILE_ROOT_MEDIUM}${photo.image_url}`
                          : two
                      }
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <PaginationLayout
        count={state.total_count}
        data={state.data}
        page={Number(match?.params.page)}
        limit={12}
        loading={false}
        onPageChange={(val) => onChangePagination(val)}
      />
    </div>
  );
};

export default PrivatePhoto;
