import React, { useEffect, useState, useContext } from "react";
import henceforthApi from "../utils/henceforthApi";
import { GlobalContext } from "../context/Provider";
import Swal from "sweetalert2";
import PaginationLayout from "../components/PaginationLayout";
import { useLocation, useMatch, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
interface innerData {
  name: string,
  email: string,
  phone_no: number,
  _id: string,
  message: string
  resolved: boolean
}
interface Contactus {
  data: Array<innerData>,
  total_count: number
}
export default () => {
  const navigate = useNavigate();
  const location = useLocation();
  const match: any = useMatch("/contact-us/:page");
  const params: any = useParams();
  const newParam = new URLSearchParams(location.search);
  let limit = 10;
  const { authState, handlePagination, handleFilter } = useContext(GlobalContext);
  const [state, setState] = useState({
    data: [],
    total_count: 0,
  } as Contactus);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState<Boolean>();
  const [readContent, setReadContent] = useState("");
  henceforthApi.setToken(authState.access_token);
  const initialiseData = async () => {
    try {
      let apiRes = await henceforthApi.ContactUs.pagination(
        Number(params.page) - 1,
        newParam.toString(),
        limit
      );
      setState({
        ...state,
        ...apiRes.data,
      });
      setLoading(false);
      console.log(apiRes)
    } catch (error: any) {
      setLoading(false);
    }
  };
  useEffect(() => {
    const init = async () => {
      setLoading(true);
      let searchData = newParam.has("search") ? newParam.get("search") : "";
      // setSearch(searchData);
      initialiseData();
    };
    init();
  }, [match.params.page, newParam.get("search")]);

  const handleResolve = (id: string) => {
    const data = {
      language: "ENGLISH",
      type: "RESOLVE",
      _id: id,
    };
    console.log("resolved");

    henceforthApi.ContactUs.resolveContact(data)
      .then((res: any) => {
        console.log(res, "Resolved succesfully");
        Swal.fire({
          title: "Contact Resolved",
          text: "Contact Resolved successfully",
          icon: "success",
          confirmButtonText: "OK",
        });
        initialiseData();
      })
      .catch((err: any) => {
        console.log("delte failed", err);
        Swal.fire({
          title: "Contact Resolved",
          text: err.response.body.error_description,
          icon: "error",
          confirmButtonText: "Cancel",
        });
      });
  };

  const handleDelete = (id: string) => {
    const data = {
      language: "ENGLISH",
      type: "DELETE",
      _id: id,
    };
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result: any) => {
      if (result.isConfirmed) {
        try {
          henceforthApi.ContactUs.deleteContact(data)
            .then((res: any) => {
              console.log(res, "Deleted succesfully");

              Swal.fire({
                title: "Contact Deleted",
                text: "Contact deleted successfully",
                icon: "success",
                confirmButtonText: "OK",
              });
              initialiseData();
            })
            .catch((err: any) => {
              console.log("delte failed", err);
              Swal.fire({
                title: "Contact Delete Failed",
                text: err.response.body.error_description,
                icon: "error",
                confirmButtonText: "Cancel",
              });
            });
        } catch (error: any) { }
      }
    });
  };

  return (
    <div className="ibox animated fadeInRight">
      <div className="ibox-title">
        <h5>Contact Us</h5>
      </div>
      <div className="ibox-content">
        <form
          onSubmit={(e: any) => {
            e.preventDefault();
          }}>
          <div className="input-group form-group">
            <input
              type="text"
              name="search"
              onChange={(e: any) => {
                handleFilter(e.target.name, e.target.value);
              }}
              placeholder="Search by Email"
              className="form-control form-control-sm"
            />
          </div>
        </form>
        <div className="table-responsive">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Sr No.</th>
                <th>Name</th>
                <th>Email</th>
                <th>Phone No</th>
                <th>Message</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            {state.total_count != 0 ?
              <tbody>
                {state.data.map((res: any, index: number) => {
                  return (
                    <tr key={res._id}>
                      <td>
                        {params.page === "0"
                          ? index + 1
                          : (params.page - 1) * limit + (index + 1)}
                        .
                      </td>
                      <td>{res.name}</td>
                      <td>{res.email ? res.email : 'email not available'}</td>
                      <td>{res.phone_no ? res.phone_no : 'Number not available'}</td>
                      <td>{res.message.length < 40 ? res.message.slice(0, 40) : res.message.slice(0, 40) + "..,"}
                        {res.message.length < 40 ? (
                          ""
                        ) : (
                          <button
                            className="readmore btn btn-xs "
                            data-bs-toggle="modal"
                            data-bs-target="#readContentModal"
                            onMouseOver={() => setReadContent(res.message)}
                          >
                            Read more
                          </button>
                        )}
                      </td>
                      <td>
                        <div className="btn-group">
                          <button
                            disabled={res.resolved ? true : false}
                            onClick={() => handleResolve(res._id)}
                            className="btn btn-default btn-xs"
                          >
                            <i className={!res.resolved ? `fas fa-info-circle me-1` : `fas fa-check-circle me-1`} ></i>
                            {!res.resolved ? "Pending" : "Resolved"}
                          </button>
                        </div>
                      </td>

                      <td>
                        <div className="btn-group">
                          <button
                            onClick={() => handleDelete(res._id)}
                            className="btn-white btn btn-xs me-2"
                          >
                            <i className="fas fa-trash-alt me-1"></i>Delete
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody> : <tbody>
                <td colSpan={6}>
                  <div className="row mt-4"><div className="col-12 text-center"><b className="text-center ms-5">Sorry ! no data to display</b></div></div>

                </td>
              </tbody>}
          </table>
        </div>

        <PaginationLayout
          count={state.total_count}
          data={state.data}
          page={Number(params.page)}
          limit={Number(limit)}
          loading={false}
          onPageChange={(val) => handlePagination(val)}
        />
      </div>
      <div
        className="modal fade"
        id="readContentModal"
        tabIndex={-1}
        aria-labelledby="readContentModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <p className="text-break">{readContent}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
