import { Link } from "react-router-dom";
import ProfileImage from "../assets/images/profile_placeholder.png";
import React,{ useState, useEffect, useContext } from "react";
import henceforthApi from "../utils/henceforthApi";
import { GlobalContext } from "../context/Provider";

const Profile = () => {
  const { authState } = useContext(GlobalContext);
  return (
    <div className="row animated fadeInRight">
      <div className="text-end">
        <Link to="/profile/edit" className="btn btn-primary-button back-btn">
          <i className="fas fa-edit me-1"></i>Edit
        </Link>
      </div>
      <div className="col-md-4">
        <div className="ibox">
          <div className="ibox-title">
            <h5>Admin Profile Details</h5>
          </div>

          <div className="ibox-content">
            <img
              src={
                authState.image 
                  ? `${henceforthApi.API_FILE_ROOT_ORIGINAL}${authState.image}`
                  : ProfileImage
              }
              alt="ProfileImage"
              className="large-profile-img"
            />
            <div className="mt-4">
              <h4>
                {authState.name.charAt(0).toUpperCase() +
                  authState.name.slice(1)}
              </h4>
              <p>
                <i className="fas fa-envelope me-2"></i>
                <span>{authState.email}</span>
              </p>

              <div className="mt-3 btn-group">
                <Link
                  to="/change-password"
                  className="btn btn-primary-button me-2 btn-sm"
                >
                  <i className="fas fa-key me-1"></i>Change Password
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;



