import React, { useState, useEffect, useContext } from 'react';
import { Link, useMatch, useNavigate, useParams, useLocation } from 'react-router-dom'
import henceforthApi from '../utils/henceforthApi';
import { GlobalContext } from "../context/Provider";
import moment from 'moment';
import { toast } from "react-toastify";
import Swal from 'sweetalert2';
import PaginationLayout from "../components/PaginationLayout";

interface coupon{
  _id:string,
  name:string,
  coupon_type:string,
  created_at:string,
  uses_time:number,
  expire_date:string
  max_discount:number,
  price:number
}

function Coupons() {
  let match = useMatch("/coupons/:page")
  let location = useLocation()
  const { authState, handlePagination, handleFilter } = useContext(GlobalContext);
  const params:any = useParams();
  const newParam = new URLSearchParams(location.search);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  henceforthApi.setToken(authState.access_token);
  let limit = 10
  const FIXED_COUPONS = "FIXED";
  const PERCENTAGE_COUPONS = "PERCENTAGE"
  const [getCoupon, setGetCoupon] = useState<Array<coupon>>([])
  const [type, setType] = useState('')
  var query = new URLSearchParams(location.search);
  const CouponList = async () => {
    try {
      let q = query.toString()
      setLoading(true)
      let apiRes = await henceforthApi.Coupons.getCouponsList(q,
        limit, Number(params.page) - 1
      )
      setGetCoupon(apiRes.data.data)
      setTotalCount(apiRes.data.total_count)
      setLoading(false)
    }
    catch {
      setGetCoupon([]);
      setTotalCount(0);
      setLoading(false)
    }
  }
  const handleDelete = (id:string) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result:any) => {
      if (result.isConfirmed) {
        try {
          henceforthApi.Coupons.delCoupons(id).then((res:any) => {
            Swal.fire({
              title: "Coupon Deleted",
              text: "Coupon deleted successfully",
              icon: "success",
              confirmButtonText: "OK",
            })
            CouponList()
          }).catch((err:any) => {
            console.log("delte failed", err);
            Swal.fire({
              title: "Coupon Delete Failed",
              text: err.response.body.error_description,
              icon: "error",
              confirmButtonText: "Cancel",
            });
          });

        }
        catch {
          console.log("error")
        }
      }
    })

  }
  const filters = [
    { name: "FIXED", value: "FIXED" },
    { name: "PERCENTAGE", value: "PERCENTAGE" },
  ]
  useEffect(() => {
    CouponList()
  }, [params.page, newParam.get("search"), query.get("type")])
  return (
    <>
      <div className="ibox animated fadeInRight">
        <div className="ibox-title d-flex align-items-center justify-content-between">
          <h5>List of all Coupons</h5>
          <form>
            <div className="d-flex align-items-center justify-content-end ">

              <div className="dropdown theme me-3">
                <button className="btn dashboard-button pe-4 ps-4 dropdown-toggle shadow-none text-white" id="sortDropdown" data-bs-toggle="dropdown"
                  aria-expanded="false">{(filters.filter(res => res.value == query.get("type"))).length ? (filters.filter(res => res.value === query.get("type")))[0].name
                    : 'All'}<i className="fas fa-chevron-down bi-chevron-down ms-2 unset-icon" style={{ fontSize: "12px" }}></i></button>
                <ul className="dropdown-menu share-icons" aria-labelledby="sortDropdown">
                  {query.has("type") &&
                    <li className="cursor-pointer"><a className="dropdown-item" onClick={() => handleFilter("type", "")}>All</a></li>
                  }
                  {filters.map(res => {
                    return <li key={res.value} className="cursor-pointer"><a className="dropdown-item" onClick={() => handleFilter("type", res.value)}>{res.name}</a></li>
                  })}
                </ul>
              </div>

              <Link to="/coupons/add" className="link-style">  <button className="btn btn-color" type="button">Add New</button></Link>
              <div>
              </div>
            
            </div>
          </form>
        </div>
        <div className="ibox-content">
          <form
            onSubmit={(e:any) => {
              e.preventDefault();
            }}
          >
            <div className="input-group form-group">
              <input className="form-control me-5" name="search" onChange={(e:any) => {
                handleFilter(e.target.name, e.target.value)
              }} type="text" placeholder="Search by Coupons" />
            </div>
          </form>

          <div className="table-responsive mt-4">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Sr No.</th>
                  <th>Coupon Name</th>
                  <th>Discount Type</th>
                  <th>Created Date</th>
                  <th>Expire Date</th>
                  <th>Discount Amount</th>
                  <th>Use Times</th>
                  <th>Action</th>
                </tr>
              </thead>
              {totalCount != 0 ?
                <tbody>
                  {getCoupon.map((res:any, index:number) => {

                    return (
                      <tr
                        key={res._id}
                      >
                        <td>
                          {params.page === "0"
                            ? index + 1
                            : (params.page - 1) * limit + (index + 1)}.
                        </td>
                        <td>  {res?.name ? `${res?.name} ` : `Not Available`}</td>
                        <td>  {res?.coupon_type ? `${res?.coupon_type} ` : `Not Available`}</td>
                        <td>{moment(Number(res?.created_at)).format("MMM DD, YYYY")}</td>
                        <td>{moment(Number(res?.expire_date)).format("MMM DD, YYYY")}</td>
                        <td>{res?.coupon_type == "FIXED" ? "$ " + res?.price : res?.max_discount + "%"}</td>
                        <td>{res?.uses_time ? res?.uses_time : "Not available"}</td>
                        <td>
                          <div className="btn-group">
                            <button
                              onClick={() => handleDelete(res._id)}
                              className="btn-white btn btn-xs me-2"
                            >
                              <i className="fas fa-trash-alt me-1"></i>Delete
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody> : <div className="row mt-4"><div className="col-12 text-center"><b className="text-center ms-5">Sorry ! no data to display</b></div></div>}
            </table>
          </div>
          <PaginationLayout
            count={totalCount}
            data={getCoupon}
            page={Number(params.page)}
            limit={Number(limit)}
            loading={loading}
            onPageChange={(val) => handlePagination(val)}
          />
        </div>
      </div>
    </>)
}
export default Coupons;
