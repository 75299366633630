import React, { useState, useEffect, useContext } from "react";
import { GlobalContext } from "../context/Provider";
import henceforthApi from "../utils/henceforthApi";
import Swal from "sweetalert2";
import { ChipButton } from "../components/BootstrapCompo";
import { toast } from "react-toastify";
interface notList {
  _id: string;
  email: string
}
interface notlistUser {
  _id: string
  name: string
}
interface notification {
  type: string,
  send_to: any,
  user_ids: any
  list_user: Array<notlistUser>,
  find_user: Array<notList>,
  selectedUser: any,
}
const ReactQuill =
  typeof window === "object" ? require("react-quill") : () => false;
const ALL_USERS = "ALL_USERS";
const SELECTED_USERS = "SELECTED_USERS";
const EMAIL = "EMAIL";
const PUSH = "PUSH";
const Notification = () => {
  const { authState, authDispatch, setLoading } = useContext(GlobalContext);
  henceforthApi.setToken(authState.access_token);
  const [description, setDesciption] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [subject, setSubject] = useState("");
  const [state, setState] = useState({
    type: EMAIL,
    send_to: ALL_USERS,
    user_ids: [],
    list_user: [],
    find_user: [],
    selectedUser: new Map(),
  } as notification);
  const radioHandler = (send_to: any) => {
    setState({
      ...state,
      send_to,
    });
  };
  const radioHandlerType = (type: any) => {
    setState({
      ...state,
      type,
    });
    setDesciption("")
  };
  function handleSubmit(e: any) {
    e.preventDefault();
    if (!subject) {
      return toast.warn("Please enter subject");
    }
    const items = {
      type: state.type,
      send_to: state.send_to,
      user_ids: state.user_ids,
      subject: subject,
      message: description,
      language: "ENGLISH",
    };
    henceforthApi.Administrator.sendNotifications(items)
      .then((res: any) => {
        Swal.fire({
          title: "Notification Sent",
          text: res.message,
          icon: "success",
          confirmButtonText: "OK",
        });
        setDesciption("");
        setSubject("");
        setState({
          ...state,
          user_ids: state.user_ids,
          list_user: [],
        });
      })
      .catch((err: any) => {
        Swal.fire({
          title: "Error!",
          text: err.response.body.error_description,
          icon: "error",
          confirmButtonText: "Cancel",
        });
      });
  }
  const handleFIlter = async (value: any) => {
    setInputValue(value);
    if (value) {
      try {
        let apiRes = await henceforthApi.Common.allUsers(value);
        setState({
          ...state,
          find_user: apiRes.data.data,
        });
      } catch (error: any) {
        setState({
          ...state,
          find_user: [],
        });
      }
    }
  };
  const onClickChip = (_id: string, name: string) => {
    const { selectedUser } = state;
    if (selectedUser.has(_id)) {
      selectedUser.delete(_id);
    } else {
      selectedUser.set(_id, name);
    }
    let rowDataId = [] as Array<string>
    let rowData = [] as Array<{ _id: string, name: string }>
    selectedUser.forEach((res: any, key: any) => {
      rowDataId.push(key);
      rowData.push({ _id: key, name: res });
    });
    setState({
      ...state,
      list_user: rowData,
      selectedUser,
      user_ids: rowDataId,
      find_user: [],
    });
    setInputValue("");
  };
  const onEditorStateChange = (e: any) => {
    setDesciption(e);
  };

  return (
    <div className="row animated fadeInRight">
      <div className="col-md-12">
        <div className="ibox">
          <div className="ibox-title">Notification</div>
          <div className="ibox-content">
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="mb-2 d-block">Select users by Email</label>
                <div className="d-flex align-items-center">
                  <div
                    onClick={(e: any) => radioHandler(ALL_USERS)}
                    className="radio-btn-notif d-flex align-items-center me-3"
                  >
                    <input
                      type="radio"
                      name="user"
                      className="me-2"
                      checked={state.send_to === ALL_USERS}
                    />
                    <label htmlFor="all">All Users</label>
                  </div>
                  <div
                    onClick={(e: any) => radioHandler(SELECTED_USERS)}
                    className=" radio-btn-notif d-flex align-items-center"
                  >
                    <input
                      type="radio"
                      name="user"
                      className="me-2"
                      checked={state.send_to === SELECTED_USERS}
                    />
                    <label htmlFor="selected">Only Selected Users</label>
                  </div>
                </div>
                {state.send_to === SELECTED_USERS ? (
                  <>
                    <div className="mt-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Select user by email"
                        value={inputValue}
                        onChange={(e: any) => handleFIlter(e.target.value)}
                      />
                      <ul className="list-group">
                        {state.find_user.map((res: any) => {
                          return (
                            <li
                              className="list-group-item"
                              onClick={() =>
                                onClickChip(res._id, `${res.email}`)
                              }
                            >
                              {res.email}{" "}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    {state.list_user.length ? (
                      <>
                        <hr />
                        <div className="form-group">
                          <label className="mb-2">Selected User</label>
                          <div className=" d-flex flex-wrap gap-1">
                            {state.list_user.map((res: any) => {
                              return (
                                <ChipButton
                                  key={res._id}
                                  _id={res._id}
                                  name={res.name}
                                  onClick={onClickChip}
                                />
                              );
                            })}
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )}
              </div>
              <div className="form-group">
                <label className="mb-2">Subject</label>
                <input
                  name="subject"
                  type="text"
                  className="form-control text-dark"
                  value={subject}
                  onChange={(e: any) => setSubject(e.target.value)}
                />
              </div>
              <div className="mb-4">
                <label className="mb-2 d-block">Select users by type</label>
                <div className="d-flex align-items-center">
                  <div
                    onClick={(e: any) => radioHandlerType(EMAIL)}
                    className="radio-btn-notif d-flex align-items-center me-3"
                  >
                    <input
                      type="radio"
                      name="type"
                      className="me-2"
                      checked={state.type === EMAIL}
                    // EMAIL
                    />
                    <label htmlFor="all">Email</label>
                  </div>
                  <div
                    onClick={(e: any) => radioHandlerType(PUSH)}
                    className=" radio-btn-notif d-flex align-items-center"
                  >
                    <input
                      type="radio"
                      name="type"
                      className="me-2"
                      checked={state.type === PUSH}
                    />
                    <label htmlFor="selected">Push</label>
                  </div>
                </div>
              </div>
              {state.type === EMAIL ?
                <div
                  style={{
                    border: "1px solid rgba(0,0,0,.2)",
                    paddingBottom: "20px",
                    background: "white",
                    marginBottom: "20px",
                    maxHeight: "200px",
                  }}
                >
                  <ReactQuill
                    theme="snow"
                    className="text-dark"
                    onChange={(e: any) => onEditorStateChange(e)}
                    placeholder={`Write your content`}
                    value={description}
                  />
                </div>
                : <textarea
                  className="w-100 text-dark"
                  onChange={(e: any) => setDesciption(e.target.value)}
                  value={description}>
                </textarea>}
              <div className="form-group">
                <button
                  type="submit"
                  className="btn dashboard-button btn-color"
                  disabled={
                    state.send_to === SELECTED_USERS &&
                    state.user_ids.length === 0
                  }
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

    </div>
  );
};

export default Notification;
