import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import henceforthApi from "../utils/henceforthApi";
import { GlobalContext } from "../context/Provider";
import React, { useContext, useEffect, useState} from "react";
import { useMatch } from "react-router-dom";
import Swal from "sweetalert2";
const ReactQuill =
  typeof window === "object" ? require("react-quill") : () => false;

export default () => {
  const { authState } = useContext(GlobalContext);
henceforthApi.setToken(authState.access_token);
  const { id } = useParams();
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const match:any=useMatch("/faq/:edit/:id")
const editFaq=async()=>{
  try {
    const res = await henceforthApi.Faq.paginationByid(match.params.id);
    let valueInArr = res.data.data;

    if (Array.isArray(valueInArr)) {
      setQuestion(valueInArr[0].question);
      setAnswer(valueInArr[0].answer);
    }
    console.log(res.password)
  } catch (error:any) {
    
  }
}
  useEffect( () => {
   editFaq()
  }, []);

  const handleSubmit = async (e:any) => {
    e.preventDefault();
    const data = {
      _id: id,
      language: "ENGLISH",
      question: question,
      answer: answer,
    };

    try {
      const res = await henceforthApi.Faq.editFaq(data);
      console.log(res, "updated");
      Swal.fire({
        title: "FAQ Updated",
        text: "FAQ updated successfully",
        icon: "success",
        confirmButtonText: "OK",
      }).then((isConfirm) => {
        if (isConfirm) {
          window.history.back();
        }
      });
    } catch (error:any) {
      console.log(error, "error");
      Swal.fire({
        title: "Error!",
        text: error.response.body.error_description,
        icon: "error",
        confirmButtonText: "Cancel",
      });
    }
  };
const handleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result:any) => {
      if (result.isConfirmed) {
        try {
          henceforthApi.Faq.deleteFaq(match.params.id)
            .then((res:any) => {
              console.log(res, "response");
              Swal.fire({
                title: "FAQ Deleted",
                text: res.data.message,
                icon: "success",
                confirmButtonText: "OK",
              });
              window.history.back();
            })
            .catch((err:any) => {
              console.log(err, "ERROR");

              Swal.fire({
                title: "Error!",
                text: err,
                icon: "error",
                confirmButtonText: "Cancel",
              });
            });
        } catch (error:any) { }
      }
    });
  };

  return (
    <div className="row animated fadeInRight">
      <div className="col-md-12">
        <div className="ibox">
          <div className="ibox-title">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h4 className="my-0">Edit FAQ</h4>
              </div>
              <button
                onClick={handleDelete}
                type="button"
                className="col-auto btn-primary-button btn back-btn"
              >
                <i className="fas fa-trash me-1"></i>Delete
              </button>
            </div>
          </div>
          <div className="ibox-content">
            <form onSubmit={handleSubmit}>
              <div className="form-row row">
                <div className="form-group col-md-12">
                  <label>Title</label>
                  <input
                    title="Please enter a valid title"
                    required
                    name="question"
                    value={question}
                    type="text"
                    className="form-control text-black"
                    onChange={(e:any) => setQuestion(e.target.value)}
                  />
                </div>
                <div className="form-group col-md-12">
                  <label>Content</label>
                  <ReactQuill
                    theme="snow"
                    className="text-black"
                    onChange={(e:any) => setAnswer(e)}
                    placeholder={`Write your content`}
                    value={answer}
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-4 col-sm-offset-2">
                  <Link to="/faqs/1" className="btn btn-white btn-sm" type="button">
                    Cancel
                  </Link>
                  <button
                    className="btn btn-primary-button btn-sm ms-2"
                    type="submit"
                  >
                    Save Changes
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
