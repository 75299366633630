import { useMatch, useNavigate } from "react-router-dom";
import React,{ useState, useContext } from 'react';
import { GlobalContext } from "../context/Provider";
import henceforthApi from "../utils/henceforthApi";
import Swal from 'sweetalert2';
import { toast } from "react-toastify";
import favicon from "../assets/images/favicon.svg"


export default ({navigate}:any) => {
  // const navigate = useNavigate();
  const match:any = useMatch('/password/reset/:code')
  const [state, setState] = useState({
    oldPass: '',
    newPass: '',
    confirmPass: '',
    language: 'ENGLISH'
  })


  const { authState, authDispatch } = useContext(GlobalContext);
  henceforthApi.setToken(authState.access_token);


  const handleChange = (e:any) => {

    console.log(e)
    const name = e.target.name;
    const value = e.target.value;

    setState({
      ...state,
      [name]: value
    })
  }



  const handleSubmit = (e:any) => {
    e.preventDefault();
    if (state.newPass === state.confirmPass) {
      henceforthApi.Administrator.changePassword(state)
        .then((res:any) => {
          console.log(res, "resposne changepassd")
          Swal.fire({
            title: 'Changed Password',
            text: 'Password Changed  successfully',
            icon: 'success',
            confirmButtonText: 'OK'
          }).then((isConfirm) => {
            if (isConfirm) {
              navigate('/profile')
            }
          })
        })
        .catch((err:any) => {
          console.log(err, "error")
          Swal.fire({
            title: 'Error!',
            text: err.response.body.error_description,
            icon: 'error',
            confirmButtonText: 'Cancel'
          })
        })
    } else {
      toast.warn("Password not match, Please try again.")
    }
  }
  const [firstEye, setFirstEye] = useState(true);
  const [secondEye, setSecondEye] = useState(true);
  const [thirdEye, setThirdEye] = useState(true);

  return (
    <div className="passwordBox animated fadeInDown">
      <div className="row">
        <div className="col-md-12">
          <div className="ibox-content mb-4">
            <div className="row mt-2 mb-3">
              <div className="col-2"><img src={favicon} alt="" height="60px" width="60px"/></div>
              <div className="col-2"><h1>Pudo</h1></div>
            </div>
            {/* <img src="" alt=""/><span>Pudo</span> */}
            <h2 className="font-bold">Reset password {match.params.code}</h2>

            <p>Enter your email address and your password will be reset and emailed to you.</p>

            <div className="row">
              <div className="col-lg-12">
                <form onSubmit={handleSubmit} className="m-t" role="form">

                  <div className="form-group  position-relative">
                    <input
                      name="newPass"
                      onChange={handleChange}
                      value={state.newPass}
                      type={secondEye ? "password" : "text"}
                      className="form-control"
                      placeholder="Enter new password"
                      required
                    />
                    <i
                      className={`fas  eye-btn fas eye-wrapper  position-absolute ${secondEye ? `fa-eye-slash` : `fa-eye`}`}
                      onClick={() => setSecondEye(!secondEye)}
                      aria-hidden="true"
                    ></i>
                  </div>

                  <div className="form-group position-relative">
                    <input
                      name="confirmPass"
                      onChange={handleChange}
                      value={state.confirmPass}
                      type={thirdEye ? "password" : "text"}
                      className="form-control"
                      placeholder="Enter confirm password"
                      required
                    />
                    <i
                      className={`fas  eye-btn eye-wrapper position-absolute ${thirdEye ? `fa-eye-slash` : `fa-eye`}`}
                      onClick={() => setThirdEye(!thirdEye)}
                      aria-hidden="true"
                    ></i>

                  </div>
                  <div className="form-group">
                    <button type="submit" className="btn btn-primary-button me-2">
                      Change Password
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
