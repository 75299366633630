import React, { ReactNode } from "react";
import  { createContext, useEffect, useReducer, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  ROLES_BACKUP,
  ROLES_CONTACT_US,
  ROLES_CREATOR,
  DIVIDEND_MANAGEMENT,
  ROLES_DASHBOARD,
  ROLES_BOOKING,
  ROLES_FAQS,
  ROLES_VEHICLE,
  ROLES_TRANSACTION,
  ROLES_NOTIFICATION,
  ROLES_POLICIES,
  ROLES_COUPONS,
  ROLES_RIDES,
  ROLES_SALES,
  ROLES_STAFF_MEMBERS,
  ROLES_STREAMING,
  ROLES_USERS,
  ROLES_COLLECTION,
  // ROLES_RIDES,
} from "../constants/actionTypes";
import { SOLD, ON_SALE, ALL } from "../constants/actionTypes";
import henceforthApi from "../utils/henceforthApi";
import logoutSuccess from "./actions/auth/logoutSuccess";
import authInitialState from "./initialStates/authInitialState";
import authInitialStaticData from "./initialStates/authInitialStaticData";
import auth from "./reducers/auth";
import static_data from "./reducers/static_data";
import { ethers } from "ethers";

import DIVIDENDS_CONTRACT_PATH from "../contracts/multi_eth_send/Dividends.sol/Dividends.json";
const DIVIDENDS_CONTRACT_ADDRESS = "0xCC970526b06E582Fe3fC4FD53484B7FcE4EdB17B"; //live
type Function=(page:number)=>void
type Function1=()=>void
type Function2=(name:string,value:string)=>void
type GlobleContextProviderProps = {
  children: ReactNode;
}
interface CommonContextType{
authState:{ access_token: String, name: String, email: String|null, phone_number: number, image: String,super_admin:string,roles:any };
handleFilter:Function2
handlePagination:Function,
logOutNow:any,
loading:Boolean,
setLoading: any;
staticDataDispatch:any;
authDispatch:any;
staffMembers:Array<string>;
staticDataState:any


}
export const GlobalContext = createContext({} as CommonContextType);


function GlobalProvider(props : GlobleContextProviderProps) {
  let navigate = useNavigate();
  const location = useLocation()
  const [loading, setLoading] = useState(false);
  // const [currentUsd, setCurrenctUsd] = useState(0);
  // const [defaultAccount, setDefaultAccount] = useState(null);
  // const [userBalance, setUserBalance] = useState(null);

  let account = [];

  const [authState, authDispatch] = useReducer(auth, authInitialState, () => {
    const localAuthState = localStorage.getItem("authState");
    return localAuthState ? JSON.parse(localAuthState) : authInitialState;
  });

  const [staticDataState, staticDataDispatch] = useReducer(
    static_data,
    authInitialStaticData,
    () => {
      const localStaticDataState = localStorage.getItem("staticDataState");
      return localStaticDataState
        ? JSON.parse(localStaticDataState)
        : authInitialStaticData;
    }
  );
  let newparam = new URLSearchParams(location.search)
  const handleFilter = (name:string, value:string) => {
    if (value) {
      newparam.set(name, value)
    } else {
      if (newparam.has(name)) {
        newparam.delete(name)
      }
    }
    if (location.pathname.startsWith("/users")) {
      navigate({ pathname: "/users/1", search: newparam.toString() })
    }
    if (location.pathname.startsWith("/booked-rides")) {
      navigate({ pathname: "/booked-rides/1", search: newparam.toString() })
    }
    if (location.pathname.startsWith("/vehicles")) {
      navigate({ pathname: "/vehicles/1", search: newparam.toString() })
    }
    if (location.pathname.startsWith("/transactions")) {
      navigate({ pathname: "/transactions/1", search: newparam.toString() })
    }
    if (location.pathname.startsWith("/coupons")) {
      navigate({ pathname: "/coupons/1", search: newparam.toString() })
    }
    if (location.pathname.startsWith("/created-rides")) {
      navigate({ pathname: "/created-rides/1", search: newparam.toString() })
    }
    if (location.pathname.startsWith("/contact-us")) {
      navigate({ pathname: "/contact-us/1", search: newparam.toString() })
    }
    if (location.pathname.startsWith("/report-user")) {
      navigate({ pathname: "/report-user/1", search: newparam.toString() })
    }
    
    if (location.pathname.startsWith("/faqs")) {
      navigate({ pathname: "/faqs/1", search: newparam.toString() })
    }
  }
const handlePagination=(page:number)=>{

// navigate({pathname:`/booked-rides/${page}` , search:newparam.toString()})
if (location.pathname.startsWith("/users")) {
  navigate({ pathname: `/users/${page}`, search: newparam.toString() })
}
if (location.pathname.startsWith("/booked-rides")) {
  navigate({ pathname: `/booked-rides/${page}`, search: newparam.toString() })
}
if (location.pathname.startsWith("/vehicles")) {
  navigate({ pathname: `/vehicles/${page}`, search: newparam.toString() })
}
if (location.pathname.startsWith("/transactions")) {
  navigate({ pathname: `/transactions/${page}`, search: newparam.toString() })
}
if (location.pathname.startsWith("/coupons")) {
  navigate({ pathname: `/coupons/${page}`, search: newparam.toString() })
}
if (location.pathname.startsWith("/created-rides")) {
  navigate({ pathname:`/created-rides/${page}`, search: newparam.toString() })
}
if (location.pathname.startsWith("/report-user")) {
  navigate({ pathname: `/report-user/${page}`, search: newparam.toString() })
}
if (location.pathname.startsWith("/contact-us")) {
  navigate({ pathname: `/contact-us/${page}`, search: newparam.toString() })
}
if (location.pathname.startsWith("/faqs")) {
  navigate({ pathname: `/faqs/${page}`, search: newparam.toString() })
}
// if(location.pathname.startsWith(`/vehicle-details`)){
//   navigate({ pathname: `/vehicle-details/${page}`, search: newparam.toString() })

// }
}

  // const handleConnect = async () => {
  //   try {
  //     if (typeof window.ethereum !== "undefined") {
  //       const accounts = await window.ethereum.request({
  //         method: "eth_requestAccounts",
  //       });
  //       account = accounts[0];
  //       console.log(account, "add");
  //       accChangeHandler(accounts[0]);

  //       console.log("MetaMask is connected");
  //     }
  //   } catch (error:any) {
  //     console.log("install metamask", error);
  //   }
  // };
  // const accChangeHandler = (newAccount) => {
  //   setDefaultAccount(newAccount);
  //   getUserBalance(newAccount.toString());
  // };

  // const getUserBalance = async (address) => {
  //   try {
  //     const balance = await window.ethereum.request({
  //       method: "eth_getBalance",
  //       params: [address, "latest"],
  //     });

  //     setUserBalance(ethers.utils.formatEther(balance));
  //   } catch (error:any) {
  //     console.log(error);
  //   }
  // };


  // window.ethereum.on("account changed", accChangeHandler);
  // const adminData = JSON.parse(localStorage.getItem('authState'));
  //   const { authState } = useContext(GlobalContext);
  henceforthApi.setToken(authState.access_token);
  console.log("adminData is", authState);

  const scrollToTop = () => {
    if (window) {
      window.scrollTo(0, 0);
    }
  };

  const logOutNow = () => {
    // setDefaultAccount([]);
    logoutSuccess({})(authDispatch);
    logoutSuccess(authInitialStaticData)(staticDataDispatch);
    navigate("/", { replace: true });
  };

  const filterNfts = [SOLD, ON_SALE, ALL];

  const staffMembers = [
    // ROLES_STAFF_MEMBERS,
    // ROLES_DASHBOARD,
    ROLES_USERS,
    // ROLES_CREATOR,
    // ROLES_NFTS,
    ROLES_BOOKING,
    ROLES_VEHICLE,
    ROLES_TRANSACTION,
    // ROLES_STREAMING,
    // ROLES_COLLECTION,

    // ROLES_SALES,
    ROLES_COUPONS,
    ROLES_RIDES,
    ROLES_FAQS,
    // DIVIDEND_MANAGEMENT,
    ROLES_POLICIES,
    ROLES_CONTACT_US,
    ROLES_NOTIFICATION,
    // ROLES_BACKUP,
  ];

  useEffect(() => {

    localStorage.setItem("authState", JSON.stringify(authState))
  }, [authState]);

  useEffect(() => {
    localStorage.setItem("staticDataState", JSON.stringify(staticDataState));
  }, [staticDataState]);

  return (
    <GlobalContext.Provider
      value={{
        authState,
        staffMembers,
        authDispatch,
        loading,
        setLoading,
        staticDataState,
        staticDataDispatch,
        logOutNow,
        handlePagination,
        handleFilter,
      }}>
      {props.children}
    </GlobalContext.Provider>
  );
}

export default GlobalProvider;
