import React from 'react'
import PaginationLayout from "../components/PaginationLayout";
import { GlobalContext } from "../context/Provider";
import { useContext, useEffect, useState } from "react";
import henceforthApi from "../utils/henceforthApi";
import moment from 'moment';

interface Ride {
  data: {
    data: Array<object>,
    total_count: number
  }
}
function RideCreated({ _id }:any) {
  const [page, setPage] = useState<number>(1)
  const [state, setState] = useState<Ride>({
    data: {
      data: [],
      total_count: 0,
    }
  })
  const [loading, setLoading] = useState(false);
  let limit = 10;
  const { authState } = useContext(GlobalContext);
  henceforthApi.setToken(authState.access_token)
  const onChangePagination = (newVal:number) => {
    setPage(Number(newVal))
  };
const getRideDetalils = async () => {
    const rideDetail = await henceforthApi.Riding.pagination(_id, page - 1, limit)
    setState(rideDetail)
  }
  useEffect(() => {
    getRideDetalils()
  }, [page])
  return (
    <div>
      <div className="row">
        <div className="ibox">
          <div className="ibox-title">
            <h5>Created Rides</h5>
          </div>
          <div className="ibox-content">
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Sr No</th>
                    <th>Pick up Location </th>
                    <th>Drop off Location</th>
                    <th>Price</th>
                    <th>Date</th>
                  </tr>
                </thead>
                {state?.data.total_count !== 0 ?
                  <tbody>
                    {state?.data.data?.map((res:any, index:number) => {
                      console.log("res", res)
                      return (
                        <tr><td className='p-3'> {index + 1}.</td>
                          <td>
                            <div className="text-nowrap">
                              {res.address_from.length > 2 ? res.address_from.slice(0, 25) + "..." : "Not available"}
                            </div>
                          </td>
                          <td>
                            {res.address_to.length > 2 ? res.address_to.slice(0, 25) + "..." : "Not available"}
                          </td>
                          <td> {res?.price ? "$" + res?.price : "Not available"}</td>
                          <td> {moment(Number(res?.created_at)).format("MMM DD, YYYY")}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                  :  <tbody >
                  <td colSpan={6}>
                  <div className="row mt-4"><div className="col-12 text-center"><b className="text-center ms-5">Sorry ! no data to display</b></div></div>
                  </td>
                </tbody>}
              </table>
              <PaginationLayout
                count={state?.data.total_count}
                data={state?.data.data}
                page={page}
                limit={Number(limit)}
                loading={loading}
                onPageChange={(val) => onChangePagination(val)}
              />
             </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default RideCreated
