import Profile from "../assets/images/profile_placeholder.png";
import henceforthApi from "../utils/henceforthApi";
import Swal from "sweetalert2";
import React,{ useState, useContext } from "react";

import { GlobalContext } from "../context/Provider";

import { Spinner } from "../components/BootstrapCompo";
import { toast } from "react-toastify";

let roleListInMap = new Map() as any;
const AddStaff = () => {
  const { authState, staffMembers } = useContext(GlobalContext);
  henceforthApi.setToken(authState.access_token);

  const [loader, setLoader] = useState(false);

  const [newUser, setNewUser] = useState<any>({
    name: "",
    // image: '',
    email: "",
    password: "",
    language: "ENGLISH",
    roles: "",
  });

  const handleFile = async (file:any) => {
    setLoader(true);
    try{
      const apiRes = await henceforthApi.Auth.uploadImage("file",file)
        console.log(apiRes, "upload image");
        setLoader(false);
        setNewUser({
          ...newUser,
          image: apiRes.data.file_name,
        });
    }catch{

    }
  }
  //   const url = `${henceforthApi.API_ROOT}Upload/do_spaces_file_upload`;

  //   const formData = new FormData();
  //   formData.append("file", file);

  //   const config = {
  //     headers: {
  //       "content-type": "multipart/form-data",
  //       token: authState.access_token,
  //     },
  //   };

  //   const apiRes = await (await axios.post(url, formData, config)).data;
  //   console.log(apiRes, "upload image");
  //   setLoader(false);
  //   setNewUser({
  //     ...newUser,
  //     image: apiRes.data.file_name,
  //   });
  // };

  const handleChange = (e:any) => {
    let name = e.target.name;
    let value = e.target.value;

    setNewUser({
      ...newUser,
      [name]: value,
    });
  };
  const handleSubmit = (e:any) => {
    e.preventDefault();
    console.log("Form values are", newUser);
    let rowData = [] as any;
    roleListInMap.forEach((element:any) => {
    rowData= [...rowData,element]
    });
    if (!newUser.name.trim()) {
      return toast.warn("Please provide name");
    }
    if (!newUser.email.trim()) {
      return toast.warn("Please provide email");
    }
    if (!rowData.length) {
      return toast.warn("Please select min 1 role");
    }
    henceforthApi.Administrator.addStaffMembers({ ...newUser, roles: rowData }).then((res:any) => {
      console.log(res, "response");
      Swal.fire({
        title: "Staff Added",
        text: res.data.message,
        icon: "success",
        confirmButtonText: "OK",
      });
      setNewUser({
        name: "",
        image: "",
        email: "",
        password: "",
        language: "ENGLISH",
        roles: "",
      });
      window.history.back();
    })
    .catch((err:any) => {
      console.log(err, "ERROR");

      Swal.fire({
        title: "Error!",
        text: err.response.body.error_description,
        icon: "error",
        confirmButtonText: "Cancel",
      });
    });
  };

  const addRoleList = (role:string, checked:boolean) => {
    if (checked) {
      roleListInMap.set(role, role);
    } else {
      roleListInMap.delete(role);
    }
  };
const [firstEyes, setFirstEyes] = useState(true);
return (
    <div className="row animated fadeInRight">
      <div className="col-md-12">
        <div className="ibox">
          <div className="ibox-title p-3">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h4>Add Staff</h4>
              </div>
              {/* <div className="col-auto">
                <Link to="/staff-details/12" className="btn-primary-button btn mr-2 back-btn">
                  <i className="fas fa-trash me-1"></i>Delete
                </Link>
              </div> */}
            </div>
          </div>
          <div className="ibox-content">
            <div className="text-center my-4">
              {loader ? (
                <Spinner />
              ) : (
                <div className="position-relative d-inline-block">
                  <img
                    className="profile-user"
                    alt=""
                    src={
                      newUser.image
                        ? `${henceforthApi.API_FILE_ROOT_ORIGINAL}${newUser.image}`
                        : Profile
                    }
                  />
                  <label className="label edit-label" htmlFor="upload-profile">
                    <i className="fa fa-camera"></i>
                  </label>
                  <input
                    onChange={(e:any) => handleFile(e.target.files[0])}
                    type="file"
                    id="upload-profile"
                    className="form-control d-none"
                  />
                </div>
              )}
            </div>
            <form onSubmit={handleSubmit}>
              <div className="form-row row">
                <div className="form-group col-md-6">
                  <label>Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Name"
                    maxLength={30}
                    name="name"
                    onChange={handleChange}
                  />
                </div>

                <div className="form-group col-md-6">
                  <label>Email</label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Enter Email"
                    maxLength={30}
                    name="email"
                    onChange={handleChange}
                  />
                </div>

                <div className="form-group col-md-6 eyes-on">
                  <label>Password</label>
                  <input
                    type={firstEyes ? "password" : "text"}
                    className="form-control"
                    placeholder="Password"
                    maxLength={30}
                    name="password"
                    onChange={handleChange}
                  />
                  <i
                    className={`fas  eye-btn ${
                      firstEyes ? `fa-eye-slash` : `fa-eye`
                    }`}
                    onClick={() => setFirstEyes(!firstEyes)}
                    aria-hidden="true"
                  ></i>
                </div>
                <div className="row">
                  {staffMembers.map((res:any) => {
                    return (
                      <span className="col-md-2">
                        <input
                          type={`checkbox`}
                          className=" me-1"
                          value="fw"
                          onChange={(e:any) => addRoleList(res, e.target.checked)}
                        />
                        {res}{" "}
                      </span>
                    );
                  })}
                </div>
              </div>
              <div className="form-group row mt-2">
                <div className="col-sm-4 col-sm-offset-2">
                  {/* <button className="btn btn-white btn-sm" type="button">
                <div className="mt-2">
                  <button className="btn btn-white btn-sm" type="button">
                    Cancel
                  </button> */}
                  <button
                    className="btn btn-primary-button btn-sm ms-2"
                    type="submit"
                    disabled={!newUser.email}
                  >
                    Add
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddStaff;
