import { Link } from "react-router-dom";
import profile_placeholder from "../assets/images/profile_placeholder.png";
import React, { useState, useEffect } from "react";
import henceforthApi from "../utils/henceforthApi";
import { useParams } from "react-router-dom";
import { Spinner } from "../components/BootstrapCompo";
import Swal from "sweetalert2";

interface staffDetail {
  name: string,
  image: string,
  is_blocked: boolean,
  is_deleted: boolean,
  email: string,
  roles: Array<string>
}
const StaffDetails = () => {
  const params: any = useParams();
  const [memberDetail, setMemberDetail] = useState<staffDetail>({
    name: '',
    image: '',
    is_blocked: false,
    is_deleted: false,
    email: '',
    roles: []
  });
  const [userData, setUserData] = useState(memberDetail);

  const staffDetail = async () => {
    const apiRes = await henceforthApi.Administrator.getStaffMemberDetail(
      params.id
    );
    console.log("apiresobnse", apiRes);
    setMemberDetail(apiRes.data);
  }
  useEffect(() => {
    console.log("reloaded");
    staffDetail()
  }, [userData]);

  const blockStaffMember = async () => {
    const payload = {
      _id: params.id,
      is_blocked: !memberDetail.is_blocked,
      is_deleted: memberDetail.is_deleted,
      language: "ENGLISH",
    };

    try {
      const response = await henceforthApi.Administrator.blockOrDeleteStaff(
        payload
      ).then((res: any) => {
        Swal.fire({
          title: memberDetail.is_blocked == false ? "Staff Blocked" : "Staff Unblocked",
          text: memberDetail.is_blocked == false ? "Staff Blocked successfully" : "Staff Unblocked successfully",
          icon: "success",
          confirmButtonText: "OK",
        })
        staffDetail()
      }).catch((err: any) => {
        console.log("Staff Blocked failed", err);
        Swal.fire({
          title: "Staff Blocked failed",
          text: err.response.body.error_description,
          icon: "error",
          confirmButtonText: "Cancel",
        });
      });
      setUserData(response.data);
      // setMemberDetail(response.data)
      console.log("User data set", userData);
    } catch (err: any) {
      console.log(err);
    }
  };

  const deleteStaffMember = async () => {
    const payload = {
      _id: params.id,
      is_blocked: memberDetail.is_blocked,
      is_deleted: true,
      language: "ENGLISH",
    };

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result: any) => {
      if (result.isConfirmed) {
        try {
          const response = await henceforthApi.Administrator.blockOrDeleteStaff(
            payload
          ).then((res: any) => {
            console.log(res, "response");
            Swal.fire({
              title: "Staff Deleted",
              text: res.data.message,
              icon: "success",
              confirmButtonText: "OK",
            });
            window.history.back();
          }).catch((err: any) => {
            console.log(err, "ERROR");
            Swal.fire({
              title: "Error!",
              text: err,
              icon: "error",
              confirmButtonText: "Cancel",
            });
          })
          setUserData(response.data);
          console.log("User data set", userData);
          // setMemberDetail(response.data)
        }
        catch (err: any) {
          console.log(err);
        }
      }
    })
    // window.history.back();
  }

  if (!memberDetail) {
    return <Spinner />;
  }
  return (
    <div className="row animated fadeInRight">
      <div className="ibox-title">
        <div className="d-flex justify-content-between align-items-center">
          <Link to="/staff/1/all" className="btn btn-primary-button me-3 px-4">
            <i className="fas fa-arrow-left me-1"></i>Back
          </Link>
          <div className="text-end">
            <Link
              className="btn btn-primary-button  me-3 px-4"
              to={`/staff/edit/${params.id}`}
            >
              <i className="fas fa-edit me-1"></i>Edit
            </Link>
          </div>
        </div>
      </div>
      <div className="ibox-content">
        <div className="col-md-4">
          <div className="ibox">
            <div className="ibox-title">
              <h5>Staff Profile Details</h5>
            </div>
            <div className="ibox-content">
              <img
                src={
                  memberDetail.image !== "null" && memberDetail.image !== null 
                    ? `${henceforthApi.API_FILE_ROOT_ORIGINAL}${memberDetail.image}`
                    : profile_placeholder
                }
                alt="ProfileImage"
                className="large-profile-img "
              />
              <div className="mt-4">
                <h4>{memberDetail.name}</h4>
                <p>
                  {" "}
                  Roles :{" "}
                  {memberDetail.roles.map((res: any, index: number) => {
                    return `${res}${memberDetail.roles.length - 1 == index ? "" : ", "
                      }`;
                  })}
                </p>
                <p> Email : {memberDetail.email}</p>
              </div>
              <div className="mt-4">
                <button
                  onClick={blockStaffMember}
                  type="button"
                  className="btn-default btn btn-sm me-2"
                >
                  <i
                    className={
                      memberDetail.is_blocked == false
                        ?
                        `fas fa-lock me-2` : `fas fa-unlock me-2`
                    }
                  ></i>
                  {memberDetail.is_blocked == false ? `Block` : `Unblock`}
                </button>
                <button
                  disabled={memberDetail.is_deleted ? true : false}
                  onClick={deleteStaffMember}
                  type="button"
                  className="btn-outline-primary btn btn-sm me-2"
                >
                  <i
                    className={
                      memberDetail.is_deleted
                        ? `fa fa-ban me-2`
                        : "fas fa-trash-alt me-2"
                    }
                  ></i>{" "}
                  {memberDetail.is_deleted ? `Deleted` : `Delete`}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StaffDetails;
