import profile_placeholder from "../assets/images/profile_placeholder.png";
import React from "react";
import {
  Link,
  useLocation,
  useMatch,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import henceforthApi from "../utils/henceforthApi";
import { GlobalContext } from "../context/Provider";
import PaginationLayout from "../components/PaginationLayout";

interface typedata {
  image: ""
}
export default () => {
  const navigate = useNavigate();
  const location = useLocation();
  const match: any = useMatch("/staff/:page/:role");
  const newParam = new URLSearchParams(location.search);
  let limit = 10;
  const { authState, staffMembers, handleFilter, handlePagination } = useContext(GlobalContext);
  const [state, setState] = useState({
    data: [] as Array<typedata>,
    total_count: 0
  });
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  henceforthApi.setToken(authState.access_token);
  const onChangePagination = (newVal: number) => {
    if (newParam.has("search")) {
      navigate(
        `/staff/${newVal}/${match.params.role}?search=${newParam.get(
          "search"
        )}`
      );
    } else {
      navigate(`/staff/${newVal}/${match.params.role}`);
    }
  };
  const onChangeRole = (role: string) => {
    if (newParam.has("search")) {
      navigate(`/staff/1/${role}?search=${newParam.get("search")}`);
    } else {
      navigate(`/staff/1/${role}`);
    }
  };
  const onClearSearch = () => {
    const newSearchparams = new URLSearchParams();
    navigate({ search: newSearchparams.toString() });
  };
  const onSearch = async (searchData: string) => {
    if (searchData) {
      navigate(`/staff/1/${match.params.role}?search=${searchData}`);
    } else {
      navigate(`/staff/1/${match.params.role}`);
    }
  };
  useEffect(() => {
    const init = async () => {
      setLoading(true);
      let searchData = newParam.has("search") ? newParam.get("search") : "";
      try {
        let apiRes = await henceforthApi.Staff.pagination(
          match.params.page,
          newParam.toString(),
          match.params.role
        );
        setState({
          ...state,
          ...apiRes.data,
        });
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
      }
    };
    init();
  }, [match.params.page, match.params.role, newParam.get("search")]);


  console.log("state", state);

  return (
    <div className="ibox animated fadeInRight">
      <div className="ibox-title d-flex align-items-center justify-content-between">
        <h5>Staff Management</h5>
        <Link to="/staff/add" className="btn btn-primary-button mb-1 back-btn">
          Add Staff
        </Link>
        <div className="dropdown">
          <button
            className="filter-by-role-btn btn btn-secondary btn-color theme-hover dropdown-toggle"
            type="button"
            id="staffDropdown"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {match.params.role == "all"
              ? "Filter by role"
              : String(match.params.role).replace("_", " ")}
          </button>
          <ul className="dropdown-menu" aria-labelledby="staffDropdown">
            {staffMembers.map((res: any) => {
              return (
                <li onClick={() => onChangeRole(res)}>
                  <a className="dropdown-item" href="#">
                    {String(res).replace("_", " ")}
                  </a>
                </li>
              );
            })}
            {match.params.role !== "all" ? (
              <li onClick={() => onChangeRole("all")}>
                <a className="dropdown-item" href="#">
                  Reset
                </a>
              </li>
            ) : (
              ""
            )}
          </ul>
        </div>
      </div>
      <div className="ibox-content">
        <form
          onSubmit={(e: any) => {
            e.preventDefault();
            onSearch(search);
          }}
        >
          <div className="input-group form-group">
            <input
              value={search}
              name="search"
              onChange={(e: any) => {
                handleFilter(e.target.name, e.target.value);
              }}
              placeholder="Search by Name, Email"
              type="text"
              className="form-control form-control-sm"
            />{" "}
          </div>
        </form>
        <div className="table-responsive">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Sr no</th>
                <th>Name</th>
                <th>Email</th>
                <th>Role</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {state.data.map((res: any, index: any) => {
                return (
                  <tr>
                    <td>
                      {match.params.page === "0"
                        ? index + 1
                        : (match.params.page - 1) * limit + (index + 1)}.
                    </td>
                    <td>
                      <img
                        src={
                          res?.image !== null && res?.image !== 'null'
                            ? `${henceforthApi.API_FILE_ROOT_ORIGINAL}${res?.image}`
                            : profile_placeholder
                        }
                        className="me-2 imageSize"
                        alt=""
                      />
                      {res.name}
                    </td>
                    <td>{res.email}</td>
                    <td>
                      {res.roles.length > 3
                        ? res.roles.slice(0, 3) + "..."
                        : res.roles + ""}
                      {/* {(Array.isArray(res.roles) && res.roles.map(res1 => { return <span className="label ">{res1+","}</span> }))} */}
                    </td>
                    <td>
                      <Link
                        to={`/staff/${res._id}`}
                        className="btn btn-xs btn-white"
                      >
                        <i className="fas fa-eye me-1"></i>
                        View
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <PaginationLayout
          count={state.total_count}
          data={state.data}
          page={Number(match.params.page)}
          limit={10}
          loading={loading}
          onPageChange={(val) => onChangePagination(val)}
        />
      </div>
    </div>
  );
};
