
import React, { useContext, useState } from "react";
import Sidebar from "../components/dashboardLayout/sidebar/SideBar";
import { Route, Routes, useLocation } from "react-router-dom";
import Dashboard from "../layout/Dashboard";
import PoliciesPage from "../layout/PoliciesPage";
import PoliciesDetailsPage from "../layout/PoliciesDetailsPage";
import PoliciesEditPage from "../layout/PoliciesEditPage";
import ContactUsPage from "../layout/ContactUsPage";
import Notification from "../layout/Notification";
import Header from "../components/dashboardLayout/header";
import Footer from "../components/Footer";
import Breadcrumb from "../components/dashboardLayout/breadcrumb";
import Profile from "../layout/profile";
import EmailTemplates from "../layout/emailTemplates";
import AddTemplates from "../layout/addTemplate";
import EditProfile from "../layout/editProfile";
import StaffManagement from "../layout/StaffManagement";
import StaffDetails from "../layout/staffDetails";
import EditStaff from "../layout/editStaff";
import UsersPage from "../layout/UsersPage";
import UserDetails from "../layout/UserDetails";

import Language from "../layout/language";
import EditLanguage from "../layout/editLanguage";
import DbBackup from "../layout/dbBackup";
import DbBackupClear from "../layout/DbBackupClear";
import AddStaff from "../layout/addStaff";
import FaqPage from "../layout/FaqPage";
import FaqEditPage from "../layout/FaqEditPage";
import AddFaq from "../layout/addFaq";
import PublicPhoto from "../layout/PublicPhoto";
import PrivatePhoto from "../layout/PrivatePhoto";
import PoliciesAddPage from "../layout/PoliciesAddPage";
import { GlobalContext } from "../context/Provider";
import { ROLES_DASHBOARD } from "../constants/actionTypes";
import UsersReportsPage from "../layout/UsersReportsPage";

import PayDividend from "../layout/PayDividend";
// import EditManagestreaming from "../layout/EditManagestreaming";
import BookingListing from "../layout/BookingListing";
import ManageTransaction from "../layout/ManageTransaction";
import BookingDetail from "../layout/BookingDetails";
// import VehicleDetail from "../layout/VehicleDetail";
import Coupons from "../layout/Coupons";
import CouponsAdd from "../layout/CouponsAdd";
import Vehicle from "../layout/Vehicle";
import AddVehicle from "../layout/addVehicle";
import RideListing from "../layout/RideListing";
import RideDetails from "../layout/RideDetails";
import ChangePassword from "../layout/changePassword";
import DelVehicle from "../layout/detailVehicle";
import ReportRide from "../layout/reportRide";
import RideCreated from "../layout/rideCreated";
// import UserVehicle from "../layout/UserVehicle";
function DashboardLayout() {
  const { authState } = useContext(GlobalContext);
  const roles = (Array.isArray(authState.roles) ? authState.roles : [])

  let location = useLocation();

  const [isVisible, setIsVisible] = useState(false);
  const toggle = () => setIsVisible(!isVisible);

  return (
    <div id="wrapper">
      <Sidebar isVisible={isVisible} toggle={toggle} />
      <div id="page-wrapper" className="gray-bg">
        <Header toggle={toggle} />
        {location.pathname !== "/" ? <Breadcrumb {...location} /> : authState.super_admin ? '' : <Breadcrumb {...location} />}
        <div className="wrapper wrapper-content">
          <Routes>
            {roles.includes(ROLES_DASHBOARD) || authState.super_admin ?
              <Route path="/" element={<Dashboard />} /> :
              <Route path="/" element={<Profile />} />}
            <Route path="/contents" element={<PoliciesPage />} />
            <Route path="/vehicles/:page" element={<Vehicle />} />
            <Route path="/vehicle/add" element={<AddVehicle />} />
            <Route path="/policies/add" element={<PoliciesAddPage />} />
            <Route path="/booked-rides/:page" element={<BookingListing />} />
            <Route path="/booking-details/:id" element={<BookingDetail />} />
            {/* <Route path="/user-vehicle/:page" element={<UserVehicle/>} /> */}
            {/* <Route path="/vehicle-details/:id" element={<VehicleDetail/>} /> */}
            <Route path="/coupons/:page" element={<Coupons/>} />
            <Route path="/transactions/:page" element={<ManageTransaction />} />
            <Route path="/policies/:type" element={<PoliciesDetailsPage />} />
            <Route path="/policies/:type/edit" element={<PoliciesEditPage />} />
            <Route path="/contact-us/:page" element={<ContactUsPage />} />
            <Route path="/reports/users/:page" element={<UsersReportsPage />} />
            <Route path="/notifications" element={<Notification />} />
            <Route path="/profile/edit" element={<EditProfile />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/created-rides/:page" element={<RideListing />} />
            <Route path="/ride-details/:id" element={<RideDetails />} />
            <Route path="/staff/:page/:role" element={<StaffManagement />} />
            <Route path="/staff/:id" element={<StaffDetails />} />
            <Route path="/staff/edit/:id" element={<EditStaff />} />
            <Route path="/staff/add" element={<AddStaff />} />
            <Route path="/email-template" element={<EmailTemplates />} />
            <Route path="/add-template" element={<AddTemplates />} />
            <Route path="/change-password" element={<ChangePassword/>} />
            {/* <Route path="/pay-dividend/:id" element={<PayDividend />} /> */}
            <Route path="/users/:page" element={<UsersPage />} />
            <Route path="/user/:id" element={<UserDetails />} />
            <Route path="/coupons/add" element={<CouponsAdd/>} />
            <Route path="/vehicle-Details/:page/:id" element={<DelVehicle/>} />
            <Route path="/ride-booking/1" element={<DelVehicle/>} /> 
            {/* <Route path="/edit-streaming/:id" element={<EditManagestreaming />} /> */}
            <Route path="/FAQs/:page" element={<FaqPage />} />
            <Route path="/faq/edit/:id" element={<FaqEditPage />} />
            <Route path="/faq/add" element={<AddFaq />} />
            <Route path="/language" element={<Language />} />
            <Route path="/ride-create/:page" element={<RideCreated />} />
            <Route path="/language/edit/:id" element={<EditLanguage />} />
            <Route path="/creator/:id/photos/public/:page" element={<PublicPhoto />} />
            <Route path="/creator/:id/photos/private/:page" element={<PrivatePhoto />} />
            <Route path="/db-backup" element={<DbBackup />} />
            <Route path="/db-clear" element={<DbBackupClear />} />
            <Route path="/report-user/:page" element={<ReportRide />} />
          </Routes>
        </div>

        <Footer />
      </div>
    </div>
  );
}

export default DashboardLayout;

