import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Profile from "../assets/images/profile_placeholder.png";
import { GlobalContext } from "../context/Provider";
import henceforthApi from "../utils/henceforthApi";
import loginSuccess from "../context/actions/auth/loginSuccess";
import Swal from "sweetalert2";

interface editprofile {
  name: string | number | readonly string[] | undefined,
  email: string | number | readonly string[] | undefined
}

const EditProfile = () => {
  const navigate = useNavigate();
  const { authState, authDispatch } = useContext(GlobalContext);
  henceforthApi.setToken(authState.access_token);
  const [loading, setLoading] = useState(false);

  const [state, setState] = useState({
    name: authState.name,
    email: authState.email,
  } as editprofile);

  const fileUpload = async (e: any) => {
    let file = e.target.files[0]
    setLoading(true);
    const apiRes = await henceforthApi.Auth.uploadImage("file", file)
    const data1 = {
      language: "ENGLISH",
      name: state.name,
    };
console.log(apiRes);

    henceforthApi.Administrator.editImgProfile(data1, apiRes.data.file_name)
      .then((res: any) => {
        setLoading(false);
        loginSuccess(res.data)(authDispatch);
      })
      .catch((err: any) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const data = {
      name: state.name,
      language: "ENGLISH",
    };
    henceforthApi.Administrator.editProfile(data)
      .then((res: any) => {
        loginSuccess(res.data)(authDispatch);
        Swal.fire({
          title: "Profile updated",
          text: "Admin profile updated successfully",
          icon: "success",
          confirmButtonText: "OK",
        }).then((isConfirm) => {
          if (isConfirm) {
            navigate(`/profile`, { replace: true });
          }
        });
      })
      .catch((err: any) => {
        console.log(err.response.body);
        Swal.fire({
          title: "Error!",
          text: err.response.body.error_description,
          icon: "error",
          confirmButtonText: "Cancel",
        });
      });
    console.log(state);
  };
  const handleInput = (e: any) => {
    let name = e.target.name;
    let value = e.target.value;
    setState({
      ...state,
      [name]: value,
    });
  };

  return (
    <div className="row animated fadeInRight">
      <div className="col-md-12">
        <div className="ibox">
          <div className="ibox-title p-3">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h4>Edit Admin</h4>
              </div>
            </div>
          </div>
          <div className="ibox-content spinerr-wrapper">
            <div className="text-center my-4 spinner-inner">
              {loading ? (
                <div className="position-relative d-inline-block">
                  <img
                    className="profile-user position-relative"
                    alt=""
                    src={
                      authState.image
                        ? `${henceforthApi.API_FILE_ROOT_ORIGINAL}${authState.image}`
                        : Profile
                    }
                  />
                  <div
                    className={`spinner-border spinner-grow-md spinerrrrr   `}
                    role="status"
                  >
                    <span className="visually-hidden"></span>
                  </div>
                  <label className="label edit-label" htmlFor="upload-profile">
                    <i className="fa fa-camera"></i>
                  </label>
                  <input
                    onChange={fileUpload}
                    type="file"
                    accept="image/*"
                    id="upload-profile"
                    className="form-control d-none"
                  />
                </div>
              ) : (
                <div className="position-relative d-inline-block">
                  <img
                    className="profile-user"
                    alt=""
                    src={
                      authState.image
                        ? `${henceforthApi.API_FILE_ROOT_ORIGINAL}${authState.image}`
                        : Profile
                    }
                  />
                  <label className="label edit-label" htmlFor="upload-profile">
                    <i className="fa fa-camera"></i>
                  </label>
                  <input
                    onChange={fileUpload}
                    type="file"
                    id="upload-profile"
                    accept="image/*"
                    className="form-control d-none"
                  />
                </div>
              )}
            </div>
            <form onSubmit={handleSubmit}>
              <div className="form-row row">
                <div className="form-group col-md-6">
                  <label> Name</label>
                  <input
                    type="text"
                    pattern="^[^-\s][a-zA-Z0-9_\s-]+$"
                    title="Please enter a valid name"
                    required
                    className="form-control"
                    placeholder="Name"
                    maxLength={30}
                    name="name"
                    value={state.name}
                    onChange={(e: any) => handleInput(e)}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label>Email</label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    name="email"
                    value={state.email}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-4 col-sm-offset-2">
                  <Link
                    to="/profile"
                    className="btn btn-white btn-sm"
                    type="reset"
                  >
                    Cancel
                  </Link>
                  <button
                    className="btn btn-primary-button btn-sm ms-2"
                    type="submit"
                  >
                    Save Changes
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProfile;
