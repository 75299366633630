import { Link } from "react-router-dom";
import henceforthApi from "../utils/henceforthApi";
import { GlobalContext } from "../context/Provider";
import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import Profile from "../assets/images/profile_placeholder.png";
import Swal from "sweetalert2";
import { Spinner } from "../components/BootstrapCompo";
import { toast } from "react-toastify";

interface staff {
  name: string,
  email: string,
  password: string,
  image: string
}

const EditStaff = () => {
  const params: any = useParams();
  const navigate = useNavigate();

  const { authState, staffMembers } = useContext(GlobalContext);
  henceforthApi.setToken(authState.access_token);
  const { id } = useParams();
  const [loader, setLoader] = useState(false);
  const [memberDetail, setMemberDetail] = useState<staff>({
    name: '',
    email: '',
    password: '',
    image: ''
  });
  const [state, setState] = useState<any>({
    selected: new Map(),
    mim: 'fvvgjy'
  });
  const editStaff = async () => {
    const apiRes = await henceforthApi.Administrator.getStaffMemberDetail(
      params.id
    );
    console.log("apiresobnse", apiRes);
    let data = apiRes.data;
    setMemberDetail(data);
    if (Array.isArray(data.roles)) {
      let newMap = new Map();
      data.roles.map((res: any) => {
        newMap.set(res, res);
      });
      setState({
        ...state,
        selected: newMap,
      });
    }
  }
  useEffect(() => {
    editStaff()
  }, []);

  const handleFile = async (e: any) => {
    setLoader(true);
    let file = e.target.files[0]
    const apiRes = await (henceforthApi.Auth.uploadImage("file", file)).data;
    setMemberDetail({
      ...memberDetail,
      image: apiRes.data.file_name,
    });
    console.log(apiRes, "upload image");
    setLoader(false);
  };
  const handleChange = (e: any) => {
    let name = e.target.name;
    let value = e.target.value;

    setMemberDetail({
      ...memberDetail,
      [name]: value,
    });
  };
  const handleCancel = () => {
    window.history.back();
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();
    let rowData = [] as Array<string>

    state.selected.forEach((element: any) => {
      rowData.push(element);
    });
    if (!rowData.length) {
      return toast.warn("Please select min 1 role");
    }
    console.log(setMemberDetail, "me");
    let items = {
      _id: id,
      name: memberDetail.name,
      email: memberDetail.email,
      language: "ENGLISH",
    } as any;
    if (memberDetail.image) {
      items["image"] = memberDetail.image;
    }
    henceforthApi.Administrator.addStaffMembers({ ...items, roles: rowData })
      .then((res: any) => {
        console.log(res);
        Swal.fire({
          title: "Profile updated",
          text: "Profile updated successfully",
          icon: "success",
          confirmButtonText: "OK",
        });
        window.history.back();
      })
      .catch((err: any) => {
        console.log(err.response.body);
        Swal.fire({
          title: "Error!",
          text: err.response.body.error_description,
          icon: "error",
          confirmButtonText: "Cancel",
        });
      });
  };

  const addRoleList = (role: string, checked: string) => {
    const { selected } = state;
    if (checked) {
      selected.set(role, role);
    } else {
      selected.delete(role);
    }
    setState({
      ...state,
      selected,
    });
  };

  return (
    <div className="row animated fadeInRight">
      <div className="col-md-12">
        <div className="ibox">
          <div className="ibox-title p-3 d-flex space-between">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h4>Edit Staff</h4>
              </div>
            </div>
          </div>
          <div className="ibox-content spinerr-wrapper">
            <div className="text-center my-4 spinner-inner">
              {loader ? (
                <div className="position-relative d-inline-block">
                  <img
                    className="profile-user position-relative"
                    alt=""
                    src={
                      memberDetail.image
                        ? `${henceforthApi.API_FILE_ROOT_SMALL}${memberDetail.image}`
                        : Profile
                    }
                  />
                  <div
                    className={`spinner-border spinner-grow-md spinerrrrr   `}
                    role="status"
                  >
                    <span className="visually-hidden"></span>
                  </div>
                  <label className="label edit-label" htmlFor="upload-profile">
                    <i className="fa fa-camera"></i>
                  </label>
                  <input
                    onChange={handleFile}
                    type="file"
                    id="upload-profile"
                    className="form-control d-none"
                  />
                </div>
              ) : (
                <div className="position-relative d-inline-block">
                  <img
                    className="profile-user"
                    alt=""
                    src={
                      memberDetail.image
                        ? `${henceforthApi.API_FILE_ROOT_SMALL}${memberDetail.image}`
                        : Profile
                    }
                  />
                  <label className="label edit-label" htmlFor="upload-profile">
                    <i className="fa fa-camera"></i>
                  </label>
                  <input
                    onChange={handleFile}
                    type="file"
                    id="upload-profile"
                    className="form-control d-none"
                  />
                </div>
              )}
            </div>
            <form onSubmit={handleSubmit}>
              <div className="form-row row">
                <div className="form-group col-md-6">
                  <label>Name</label>
                  <input
                    type="text"
                    className="form-control"
                    pattern="^[^-\s][a-zA-Z0-9_\s-]+$"
                    title="Please enter a valid name"
                    required
                    placeholder="Name"
                    maxLength={30}
                    name="name"
                    onChange={handleChange}
                    value={memberDetail.name}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label>Email</label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Enter Email"
                    name="email"
                    onChange={handleChange}
                    value={memberDetail.email}
                  />
                </div>
                <div className="row">
                  {staffMembers.map((res: any) => {
                    return (
                      <span className="col-md-2">
                        <input
                          type={`checkbox`}
                          className=" me-1"
                          value="fw"
                          checked={state.selected.has(res)}
                          onChange={(e: any) => addRoleList(res, e.target.checked)}
                        />
                        {res}{" "}
                      </span>
                    );
                  })}
                </div>
              </div>
              <div className="form-group row mt-3">
                <div className="col-sm-4 col-sm-offset-2">
                  <button
                    onClick={handleCancel}
                    className="btn btn-white btn-sm"
                    type="button"
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-primary-button btn-sm ms-2"
                    type="submit"
                  >
                    Save Changes
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditStaff;
