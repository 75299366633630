import React, { useEffect, useState, useContext } from "react";
import henceforthApi from "../utils/henceforthApi";
import { GlobalContext } from "../context/Provider";
import { Link, useLocation, useMatch, } from "react-router-dom";
import PaginationLayout from "../components/PaginationLayout";
interface faq{
  question:string;
  _id:string
  answer:string
}
export default () => {
  const location = useLocation();
  const match: any = useMatch("/faqs/:page");
  const newParam = new URLSearchParams(location.search);
  let limit = 10;
  const { authState, handlePagination, handleFilter } = useContext(GlobalContext);
  const [pageData, setPageData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [state, setState] = useState({
    data: [] as Array<faq>,
    total_count: 0,
  });
  henceforthApi.setToken(authState.access_token);
  useEffect(() => {
    const init = async () => {
      try {
        let apiRes = await henceforthApi.Faq.pagination(
          match.params.page,
          newParam.toString(),
          limit
        );
        setState({
          ...state,
          ...apiRes.data,
        });
        setTotalCount(apiRes.data.total_count);
        setPageData(apiRes.data.data);
      } catch (error:any) {
        setPageData([]);
        setTotalCount(0);
      }
    };
    init();
  }, [match.params.page, newParam.get("search")]);

  return (
    <div className="ibox animated fadeInRight">
      <div className="back-btn">
        <Link to="/faq/add" className="btn btn-primary-button">
          Add FAQ
        </Link>
      </div>
      <div className="ibox-title">
        <h5>FAQs</h5>
      </div>
      <div className="ibox-content">
        <form
          onSubmit={(e:any) => {
            e.preventDefault();
          }}
        >
          <div className="input-group form-group">
            <input
              name="search"
              onChange={(e:any) => {
                handleFilter(e.target.name, e.target.value);
              }}
              placeholder="Search by FAQ"
              type="text"
              className="form-control form-control-sm"
            />{" "}
          </div>
        </form>
        <div className="accordion" id="accordionExample">
          {state.data.map((res:any, index:number) => (
            <div className="accordion-item mb-3">
              <h2 className="accordion-header mt-0" id="headingOne">
                <button
                  className="accordion-button btn-primary-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#collapseOne${index}`}
                  aria-expanded="true"
                  aria-controls={`collapseOne${index}`}
                >
                  {res.question}
                  {/* <span className="me-1">{page === '0' ? index + 1 : ((page - 1) * limit) + (index + 1)}.{" "} </span>{res.question}{" "} */}
                  <Link to={`/faq/edit/${res._id}`} className="edit-icon">
                    <i className="fas fa-pen"></i>
                  </Link>
                </button>
              </h2>
              <div
                id={`collapseOne${index}`}
                className="accordion-collapse collapse"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div
                  className="accordion-body word-break-faq"
                  dangerouslySetInnerHTML={{ __html: res.answer }}
                ></div>
              </div>
            </div>
          ))}
          <PaginationLayout
            count={state.total_count}
            data={state.data}
            page={Number(match.params.page)}
            limit={10}
            loading={false}
            onPageChange={(val) => handlePagination(val)}
          />
        </div>
      </div>
    </div>
  );
};
