// import React, { useEffect, useState } from "react";
// import {Link} from "react-router-dom"
// import henceforthApi from "../utils/henceforthApi";
import React, { useState, useEffect, useContext } from 'react';
import { Link, useMatch, useNavigate, useParams, useLocation } from 'react-router-dom'
import henceforthApi from '../utils/henceforthApi';
import { GlobalContext } from "../context/Provider";
import moment from 'moment';
import { toast } from "react-toastify";
import Swal from 'sweetalert2';
import PaginationLayout from "../components/PaginationLayout";
import { Spinner } from '../components/BootstrapCompo';
interface modalData {
  name: string
}
interface modalFecth {
  brand_id: null | string
  brand_name: string
  created_at: string
  name: string
  _id: string
}
interface dateFetch {
  total_count: number,
  data: Array<modalFecth>
}
interface modalG {
  created_at: string
  modal: dateFetch
  name: string
  _id: string
}
interface modalObje {
  total_count: number
  data: Array<modalData>
}
interface Arrays {
  name: string,
  _id: string
  modal: modalObje
}
interface state {
  brand: Array<Arrays>,
  total_count: null | number,
  loading: boolean

}

const Vehicle = () => {
  const [brand, setBrand] = useState([])
  let match: any = useMatch("/vehicles/:page")
  let navigate = useNavigate()
  let location = useLocation()
  const { authState, handleFilter, handlePagination } = useContext(GlobalContext);
  const params: any = useParams();
  const newParam = new URLSearchParams(location.search);
  const [searchData, setSearchData] = useState<string | null>("");
  const [start_date, setStartDate] = useState(0);
  const [loading, setLoading] = useState(true)
  const [totalCount, setTotalCount] = useState(0);
  const [storedata, setStoredata] = useState([])
  const [state, setState] = useState({
    brand: [],
    total_count: null,
    loading: false
  } as state)
  henceforthApi.setToken(authState.access_token);
  let limit = 10
  const GetVehicle = async () => {
    let searchData = newParam.has("search") ? newParam.get("search") : "";
    try {
      setLoading(true)
      let apiRes = await henceforthApi.Vehicle.getBrand(newParam.toString()
        , Number(params.page) - 1, limit)
      let brandData = apiRes.data
      let allBrandData = brandData.data
      let total_count = brandData.total_count
      if (Array.isArray(allBrandData)) {
        let newBrand = [] as Array<modalG>
        await Promise.all(allBrandData.map(async res => {
          // console.log(res)
          try {
            const modalsData = await henceforthApi.Vehicle.getModals(res.name,0, '')
            // console.log(modalsData.data)
            newBrand.push({
              ...res,
              modal: modalsData.data,
            })
            // console.log(total_count)
            // console.log(newBrand.modal)
          } catch (error: any) {
          }
        }))
        console.log(newBrand);

        setState({
          ...state,
          brand: newBrand,
          total_count,
          loading: true
        })
      }
    }
    catch {
    } finally {
      setLoading(false)
    }

  }
  // const onChangePagination = (newVal) => {
  //   const newParam = new URLSearchParams(location.search);
  //   if (newParam.has("search")) {
  //     navigate(`/vehicles/${newVal}?search=${newParam.get("search")}`);
  //   } else {
  //     navigate(`/vehicles/${newVal}`);
  //   }
  // };
  // const onSearch = async (searchData) => {
  //   if (searchData) {

  //     navigate(`/vehicles/1?search=${searchData}`);

  //   } else {
  //     navigate(`/vehicles/1`);
  //   }
  // };
  useEffect(() => {
    let searchData = newParam.has("search") ? newParam.get("search") : null;
    setSearchData(searchData)
    GetVehicle()
    setStoredata([...storedata])
    // console.log(storedata)
  }, [params.page, newParam.get("search")])
  return (
    <div className="ibox animated fadeInRight">
      <div className="ibox-title d-flex align-items-center justify-content-between">
        <h5>List of all Vehicles</h5>
        <form>
          <div className="d-flex align-items-center justify-content-end ">
            <div className='d-flex'>
              <Link to="/vehicle/add"><button className="btn btn-color" type="button">Add Vehicle</button></Link>
            </div>
          </div>
        </form>
      </div>
      <div className="ibox-content">
        <form onSubmit={(e: any) => {
          e.preventDefault();
        }}
        >
          <div className="input-group form-group">
            <input
              name="search"
              // onKeyPress="searchRegex"
              onChange={(e: any) => {
                handleFilter(e.target.name, e.target.value);
              }}
              placeholder="Search by Vehicle brand "
              type="text"
              className="form-control form-control-sm"
            />{" "}

          </div>
        </form>

        <div className="container-fluid mt-4">
          <div className="table-responsive">
            <table className="table table-striped">
              <thead >
                <tr>
                  <th>Sr No.</th>
                  <th>Vehicle Brand</th>
                  <th>Vehicle Model</th>
                  <th>Action</th>

                </tr>
              </thead>
              <tbody>
                {
                  state.brand.map((res: any, index: number) => {
                    // console.log("res", res)
                    return (
                      <tr key={res._id}>
                        <td>  {params.page === "0"
                          ? index + 1
                          : (params.page - 1) * limit + (index + 1)}.</td>
                        <td>{res?.name ? res?.name : "Not avialable"} </td>
                        <td>
                          <div className='d-flex'>{res?.modal?.total_count != 0 ? res?.modal?.data.slice(0, 6).
                            map((item: any, index: number) => <p >{item?.name?.length ? item?.name + `${index < res?.modal?.data.length - 1 ? index < 5 ? ', ' : '' : ''}` : "Not available"}</p>) : "Not available"}
                          </div>
                        </td>

                        <td>
                          <div className="d-flex">
                            <Link
                              to={`/vehicle-details/1/${res?._id}`}
                              className="btn btn-xs btn-white me-2"
                            >
                              <i className="fas fa-eye me-1"></i>
                              View
                            </Link>
                          </div>
                        </td>

                      </tr>
                    )
                  })
                }

              </tbody>
            </table>


          </div>
          <PaginationLayout
            count={Number(state.total_count)}
            data={state.brand}
            page={Number(params.page)}
            limit={Number(limit)}
            loading={loading}
            onPageChange={(val) => handlePagination(val)}
          />
        </div>

      </div>
    </div>

  )
}
export default Vehicle;