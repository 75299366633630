import React from "react";
import { useContext, useState} from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
// import logo from "../assets/images/favicon.ico";
import { Spinner } from "../components/BootstrapCompo";
import loginSuccess from "../context/actions/auth/loginSuccess";
import { GlobalContext } from "../context/Provider";
import henceforthApi from "../utils/henceforthApi";

export default ({ logOutNow,navigate }:any) => {
  const { authDispatch } = useContext(GlobalContext);

  const [state, setState] = useState({
    email: "",
    password: "",
    loading: false,
  });

  const onSubmitForm = () => {
    console.log("onSubmitForm called");
    setState({
      ...state,
      loading: true,
    });
    const { email, password } = state;
    let items = {
      email: email,
      password: password,
      language: "ENGLISH",
    };
    console.log("items", items);
    henceforthApi.Auth.login(items)
      .then((res:any) => {
        console.log(res)
        loginSuccess(res.data)(authDispatch);
        toast.success("Login Successful");
      })
      .catch((err:any) => {
        console.log("err message", err);
        let response = err?.response?.body;
        console.log("response", response);
        let error = response ? response.error_description : "Server error";
        toast.error("Wrong UserName or Password");
        if (error.error) {
          if (String(error.error).includes("unauthorized")) {
            logOutNow();
          } else {
            setState({
              ...state,
              loading: false,
            });
          }
        } else {
          setState({
            ...state,
            loading: false,
          });
        }
      });
  };

  const handleInput = (e:any) => {
    let name = e.target.name;
    let value = e.target.value;
    setState({
      ...state,
      [name]: value,
    });
  };

  const [firstEye, setFirstEye] = useState<boolean>(true);




  return (
    <div className="Login">
      <div className="middle-box text-center loginscreen animated fadeInDown">
        <div>
          <div className=" text-center">
            <h1 className="logo-name mt-5 mb-3"> PUDO</h1>
            {/* <img
              className="logo-img"
              height={100}
              src={logo}
            /> */}
          </div>
          <h2 className="mx-3 pudo-color">Welcome to PUDO</h2>
          <form
            className="m-t"
            role="form"
            onSubmit={(e:any) => {
              e.preventDefault();
              onSubmitForm();
            }}
          >
            <div className="form-group">
              <input
                type="email"
                className="form-control"
                placeholder="Username"
                name="email"
                value={state.email}
                onChange={(e:any) => handleInput(e)}
                required
              />
            </div>
            <div className="form-group">
              <input
                type={firstEye ? "password" : "text"}
                className="form-control"
                placeholder="Password"
                name="password"
                value={state.password}
                onChange={(e:any) => handleInput(e)}
                required
              />
              <i
                className={`fas  eye-btn ${firstEye ? `fa-eye-slash` : `fa-eye`}`}
                onClick={() => setFirstEye(!firstEye)}
                aria-hidden="true"
              ></i>
            </div>
            <button
              type="submit"
              className="btn btn-color block full-width m-b "
              disabled={state.loading || !state.email || !state.password}
            >
              {state.loading ? <Spinner /> : "Login"}
            </button>

            {/* <Link to="/password/forgot" className="text-black">
              <small>Forgot password</small>
            </Link> */}
          </form>
        </div>
      </div>
    </div>
  );
};
