import React from 'react';
import PaginationLayout from "../components/PaginationLayout";
import { GlobalContext } from "../context/Provider";
import { useContext, useEffect, useState } from "react";
import henceforthApi from "../utils/henceforthApi";
interface driverDetail {
  name: string
}
interface ridebook {
  city_from: string
  booking_status: string
  city_to: string
  total_price: number
  booked_seats: number
  driver_detail: driverDetail
  id: string
}
function RideBooked({ _id }: any) {
  const [page, setPage] = useState<number>(1)
  const [state, setState] = useState({
    data: {
      data: [] as Array<ridebook>,
      total_count: 0
    }
  })
  const [loading, setLoading] = useState(false);
  let limit = 10;
  const { authState } = useContext(GlobalContext);
  henceforthApi.setToken(authState.access_token)
  const onChangePagination = (newVal: number) => {
    setPage(Number(newVal))
  };
  const bookingDetails = async () => {
    const bookDetail = await henceforthApi.User.pagination(_id, page - 1, limit)
    setState(bookDetail)
  }

  useEffect(() => {
    bookingDetails()
  }, [page])
  return (
    <div className='row'>
      <div className="ibox">
        <div className="ibox-title">
          <h5>Booked Rides</h5>
        </div>
        <div className="ibox-content">
          <div className="table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Sr No</th>
                  <th>Passenger</th>
                  <th>Pick up Location </th>
                  <th>Drop off Location</th>
                  <th>Status</th>
                  <th>Booked Seats</th>
                  <th>Price</th>
                </tr>
              </thead>
              {state.data.total_count != 0 ?
                <>
                  <tbody>
                    {state.data.data.map((res: any, index: number) => {
                      return (
                        <tr key={res.id}>

                          <td>
                            {page === 0
                              ? index + 1
                              : (page - 1) * limit + (index + 1)}
                            .
                          </td>
                          <td>
                            <div className="text-nowrap">
                              {res?.driver_detail?.name
                                ? res?.driver_detail?.name : "Not available"}
                            </div>
                          </td>
                          <td>
                            {res?.city_to?.length > 2 ? res?.city_to.slice(0, 20) : "Not available"}
                          </td>
                          <td>
                            {res?.city_from?.length > 2 ? res?.city_from.slice(0, 20) : "Not available"}
                          </td>
                          <td>
                            {res?.booking_status ? res?.booking_status : "Not available"}
                          </td>
                          <td>
                            {res?.booked_seats ? res?.booked_seats : "Not available"}
                          </td>
                          <td>
                            {res?.total_price ? "$" + res?.total_price : "Not available"}
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                  {state.data?.total_count > 10 ?
                    <div className="view-more mb-2">
                      <div className="d-flex align-items-center justify-content-center">
                        {/* <Link
                            to="/booked-Rides/1"
                            className="btn btn-xs btn-white me-2"
                          >
                            View More
                          </Link> */}
                      </div>
                    </div> : ""
                  }
                </> :
                <tbody >
                  <td colSpan={6}>
                  <div className="row mt-4"><div className="col-12 text-center"><b className="text-center ms-5">Sorry ! no data to display</b></div></div>
                  </td>
                </tbody>}
            </table>
            <PaginationLayout
              count={state.data.total_count}
              data={state.data.data}
              page={page}
              limit={Number(limit)}
              loading={loading}
              onPageChange={(val) => onChangePagination(val)}
            />
            <div className="view-more mb-2">
              <div className="d-flex align-items-center justify-content-center">

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RideBooked
