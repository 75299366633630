import React from "react";
import PaginationLayout from "../components/PaginationLayout";
import profile_placeholder from "../assets/images/profile_placeholder.png";
import { Link, useLocation, useMatch } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import henceforthApi from "../utils/henceforthApi";
import { GlobalContext } from "../context/Provider";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import DownloadfileModal from "../components/common/downloadfileModal";
interface driver {
  profile_pic: string
  name: string
}
interface userlist {
  profile_pic: string
  name: string
}
interface book {
  _id: string,
  booked_seats: number,
  total_price: number,
  booking_status: string,
  city_from: string,
  city_to: string,
  created_at: string
  driver_detail: driver
  user_detail: userlist


}
const BookingListing = () => {
  const match = useMatch("/booked-rides/:page")
  const location = useLocation();
  let limit = 10;
  const { authState, handleFilter, handlePagination } = useContext(GlobalContext);
  const params: any = useParams();
  const newParam = new URLSearchParams(location.search);
  const [loading, setLoading] = useState(false);
  const [pageData, setPageData] = useState<Array<book>>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [start_date, setStartDate] = useState(0);
  const [end_date, setEndDate] = useState(0);

  henceforthApi.setToken(authState.access_token);
  var query = new URLSearchParams(location.search);
  
  // console.log(location);

  const filters1 = [
    { name: "Newest Booking", value: "1" },
    { name: "Oldest Booking", value: "2" }
  ]
  const filters = [
    { name: "COMPLETED", value: "COMPLETED" },
    { name: "PENDING", value: "PENDING" },
    { name: "ONGOING", value: "ONGOING" },
    { name: "CONFIRMED", value: "CONFIRMED" },
    { name: "DECLINED", value: "DECLINED" },
    { name: "CANCELLED", value: "CANCELLED" },
  ]
  const downloadCsv = async (start_date:string,end_date:string) => {
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    try {
      let apiRes = await henceforthApi.BookingListing.export(
        moment(start_date).format("DD/MM/YYYY"),
        moment(end_date).format("DD/MM/YYYY"),
        // timezone
      );
      let data = apiRes.data.recent_bookings;
      console.log(data);

      const rows = [
        [
          "Sr.No.",
          "Username",
          "Pick up address",
          "Drop off address",
          "Driver Name",
          "Seats",
          "Price",
          "Date",
          "Status",
          ],
      ];
      if (Array.isArray(data)) {
        data.map((res: any,index:number) => {
          rows.push([
            index+1,
            res?.user_detail?.name,
            res?.city_from?.split(",").join(""),
            res?.city_to?.split(",").join(""),
            res?.driver_detail?.name,
            res?.booked_seats,
            res?.total_price,
            moment(Number(res?.created_at)).format("MMM DD YYYY"),
            res?.booking_status,
  ]);
        });
      }
      console.log(rows);

      let csvContent =
        "data:text/csv;charset=utf-8," +
        rows.map((e: any) => e.join(",")).join("\n");
      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", `user_${moment().valueOf()}.csv`);
      document.body.appendChild(link); // Required for FF

      link.click(); // This will download the data file named "my_data.csv".
      let closeModal = document.getElementById("closeModal");
      if (closeModal) {
        closeModal.click();
      }
    } catch (error: any) { }
  };
  const init = async () => {
    setLoading(true);
    let q = query.toString()
    try {
      let apiRes = await henceforthApi.BookingListing.getPagnation(
        Number(params.page) - 1,
        q,
        limit,
      );
      setTotalCount(apiRes.data.count);
      setPageData(apiRes.data.recent_bookings);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      setPageData([]);
      setTotalCount(0);
    }
  };
  useEffect(() => {
    
    init();
  }, [match?.params.page, newParam.get("search"), query.get("status"), query.get("sort")]);

  return (
    <>
      <div className="ibox animated fadeInRight">
        <div className="ibox-title d-flex align-items-center justify-content-between">
          <h5>List of all Bookings</h5>
            <div className="d-flex align-items-center justify-content-end ">
              <div className="dropdown theme me-3">
                <button className="btn dashboard-button pe-4 ps-4 dropdown-toggle shadow-none text-white" id="sortDropdown" data-bs-toggle="dropdown"
                  aria-expanded="false">{(filters.filter(res => res.value == query.get("status"))).length ? (filters.find(res => res.value === query.get("status")))?.name
                    : 'All Bookings'}<i className="fas fa-chevron-down bi-chevron-down ms-2 unset-icon" style={{ fontSize: "12px" }}></i></button>
                <ul className="dropdown-menu share-icons" aria-labelledby="sortDropdown">
                  {query.has("status") &&
                    <li className="cursor-pointer"><a className="dropdown-item" onClick={() => handleFilter("status", "")}>ALL</a></li>
                  }
                  {filters.map(res => {
                    return <li key={res.value} className="cursor-pointer"><a className="dropdown-item" onClick={() => handleFilter("status", res.value)}>{res.name}</a></li>
                  })}
                </ul>
              </div>
              <div> <div className="dropdown theme me-3">
                <button className="btn dashboard-button pe-4 ps-4 dropdown-toggle shadow-none text-white" id="sortDropdown" data-bs-toggle="dropdown"
                  aria-expanded="false">{(filters1.filter(res => res.value == query.get("sort"))).length ? (filters1.filter(res => res.value === query.get("sort")))[0]?.name
                    : 'Sort Time'}<i className="fas fa-chevron-down bi-chevron-down ms-2 unset-icon" style={{ fontSize: "12px" }}></i></button>
                <ul className="dropdown-menu share-icons" aria-labelledby="sortDropdown">
                  {query.has("sort") &&
                    <li className="cursor-pointer"><a className="dropdown-item" onClick={() => handleFilter("sort", "")}>ALL</a></li>
                  }
                  {filters1.map(res => {
                    return <li key={res.value} className="cursor-pointer"><a className="dropdown-item" onClick={() => handleFilter("sort", res.value)}>{res.name}</a></li>
                  })}

                </ul>
              </div>
              </div>
              <button
                className="btn btn-primary-button  ms-4"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal">
                Export
              </button>
              
            </div>
        </div>
        <div className="ibox-content">
          <div className="input-group form-group">
            <input
              name="search"
              onChange={(e: any) => {
                handleFilter("search", e.target.value);
              }}
              placeholder="Search by Username or Driver Name"
              type="text"
              className="form-control form-control-sm" />
          </div>

          <div className="table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Sr No.</th>
                  <th>Username</th>
                  <th>Pick up address</th>
                  <th>Drop off address</th>
                  <th>Driver Name</th>
                  <th>Seats</th>
                  <th>Price</th>
                  <th>Date</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {pageData.map((res: any, index: number) => {
                  return (
                    <tr key={res._id}>
                      <td>
                        {params.page === "0" ? index + 1 : (params.page - 1) * limit + (index + 1)}.
                      </td>
                      <td>
                        <div className="text-nowrap">
                          <img
                            src={
                              res?.user_detail?.profile_pic !== null && res?.user_detail?.profile_pic !== 'null' && res?.user_detail?.profile_pic !== undefined 
                                ? `${henceforthApi.API_FILE_ROOT_ORIGINAL}${res?.user_detail?.profile_pic}`
                                : profile_placeholder
                            }
                            className="me-2 imageSize"
                            alt=""
                          />
                          {res?.user_detail?.name ? res?.user_detail?.name : `Not Available`}
                        </div>
                      </td>
                      <td title={res?.city_from}>{res?.city_from?.length > 4 ? res.city_from.slice(0, 20) : "Not available"}</td>
                      <td title={res?.city_to}>{res?.city_to?.length > 4 ? res.city_to.slice(0, 20) : "Not available"}</td>
                      <td>{res.driver_detail?.name ? res.driver_detail?.name : "Not available"}</td>
                      <td>{res.booked_seats ? res.booked_seats : "Not available"}</td>
                      <td>{res.total_price ? "$ " + `${res.total_price}` : "Not available"}</td>
                      <td>{moment(Number(res.created_at)).format("MMM DD ,YYYY") ? moment(Number(res.created_at)).format("MMM DD, YYYY") : "Not available"}</td>
                      <td > {res?.booking_status ? res.booking_status : "Not available"}</td>
                      <td>
                        <div className="d-flex">
                          <Link
                            to={`/booking-details/${res._id}`}
                            className="btn btn-xs btn-white me-2">
                            <i className="fas fa-eye me-1"></i>
                            View
                          </Link>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <PaginationLayout
            count={totalCount}
            data={pageData}
            page={Number(params.page)}
            limit={Number(limit)}
            loading={loading}
            onPageChange={(val) => handlePagination(val)}
          />
        </div>
      </div>
      <DownloadfileModal data={downloadCsv} />
    </>
  )
}

export default BookingListing;