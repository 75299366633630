import React, { useState } from 'react'
import henceforthApi from '../utils/henceforthApi';
import Swal from 'sweetalert2';

function ChangePassword() {
  const [showpassword, setShowpassword] = useState(true)
    const [hidepassword, setHidepassword] = useState(false)
    const [showpassword1, setShowpassword1] = useState(true)
    const [hidepassword1, setHidepassword1] = useState(false)
  const [password, setPassword] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: ""
  })
const ShowPass=()=>{
setShowpassword(false)
setHidepassword(true)
}
const HidePass=()=>{
setHidepassword(true)
setShowpassword(true)
}
const ShowPass1=()=>{
  setShowpassword1(false)
  setHidepassword1(true)
  }
  const HidePass1=()=>{
  setHidepassword1(true)
  setShowpassword1(true)
  }
  const handleChange = (e:any) => {
    let name = e.target.name;
    let value = e.target.value;
    setPassword({
      ...password,
      [name]: value
    })
  }
  const handleSubmit = async (e:any) => {
    e.preventDefault();
    let data = {
      old_password: password.oldPassword,
      new_password: password.newPassword
    }
    try {
      let apiRes = await henceforthApi.Auth.changePassword(data)
        .then((res:any) => {
          console.log(res, "response");
          Swal.fire({
            title: "Password change SuccessFul",
            text: res.data.message,
            icon: "success",
            confirmButtonText: "OK",
          });
          window.history.back();
        }).catch((err:any) => {
          console.log(err, "ERROR");

          Swal.fire({
            title: "Enter correct password",
            text: err,
            icon: "error",
            confirmButtonText: "Cancel",
          });
        });
      setPassword({
        oldPassword: "",
        newPassword: "",
        confirmPassword: ""
      })
 }
    catch {}
 }
  return (
    <div className='container'>
      <div className='row mt-4'>
        <form onSubmit={handleSubmit}>
          <div className='col-12 input-password'>
            <input className='form-control' type={showpassword ?"password":"text"} onChange={handleChange} name="oldPassword" value={password.oldPassword} required placeholder='Enter old password' />
          {
            showpassword? <i className="far fa-eye eye-icon" onClick={ShowPass}  ></i>:<i className="fas fa-eye-slash eye-icon"onClick={HidePass}  ></i>
          } 
          </div>
          <div className='col-12 mt-3 input-password'>
            <input className='form-control' onChange={handleChange} type={showpassword1 ?"password":"text"} name="newPassword" required value={password.newPassword} placeholder='Enter new password' />
            {
            showpassword1? <i className="far fa-eye eye-icon" onClick={ShowPass1}  ></i>:<i className="fas fa-eye-slash eye-icon"onClick={HidePass1}  ></i>
          } 
          </div>
          {/* <div className='col-12 mt-3'>
            <input className='form-control' onChange={handleChange} name="confirmedPassword" value={password.confirmPassword} placeholder='Enter confirmed password' />
        </div> */}
          <div className='row'>
            <div className='col-1 mt-3'><button className='btn btn-color' type='button' onClick={()=>{window.history.back()}} >Cancel</button></div>
            <div className='col-3 mt-3'><button className='btn btn-color' type="submit">Change Password</button></div>
          </div>

        </form>
      </div>
    </div>
  )
}

export default ChangePassword
